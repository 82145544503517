import Swal from 'sweetalert2';
import * as actionTypes from '../actionTypes';
import axios from 'axios';

export const clearMsgAllStudent = () => {
  return {
    type: actionTypes.CLEAR_MSG_ALL_STUDENTS,
  };
};

export const fetchAllStart = () => {
  return {
    type: actionTypes.FETCH_ALL_STUDENTS_START,
  };
};
export const fetchAllSuccess = (students: any, count: any) => {
  return {
    type: actionTypes.FETCH_ALL_STUDENTS_SUCCESS,
    students,
    count,
  };
};
export const fetchAllFail = (error: any) => {
  return {
    type: actionTypes.FETCH_ALL_STUDENTS_FAIL,
    error,
  };
};

export const fetchAllStudents = (pageN: any, pageSize: any, search = '') => {
  return (dispatch: any) => {
    dispatch(fetchAllStart());
    let data = { pagenumber: pageN, pagesize: pageSize, search };
    axios
      .post(
        `admin/getallstudents.php
        `,
        data
      )
      .then((res) => {
        console.log(res);
        dispatch(fetchAllSuccess(res.data.data.students, res.data.data.count));
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          dispatch(fetchAllFail(err.response.data.msg));
        } else {
          dispatch(fetchAllFail('Internet Connection Failed'));
        }
      });
  };
};

// for assign trip from admin

export const assignTripToStudentStart = () => {
  return {
    type: actionTypes.ASSIGN_TRIP_STUDENT_START,
  };
};
export const assignTripToStudentSuccess = (msg: any) => {
  return {
    type: actionTypes.ASSIGN_TRIP_STUDENT_SUCCESS,
    msg,
  };
};
export const assignTripToStudentFail = (error: any) => {
  return {
    type: actionTypes.ASSIGN_TRIP_STUDENT_FAIL,
    error,
  };
};

export const assignTripToStudent = (data: any) => {
  return (dispatch: any) => {
    dispatch(assignTripToStudentStart());

    axios
      .post(
        `admin/adminassigntrip.php
        `,
        data
      )
      .then((res) => {
        console.log(res);
        dispatch(assignTripToStudentSuccess(res.data.msg));
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          dispatch(assignTripToStudentFail(err.response.data.msg));
        } else {
          dispatch(assignTripToStudentFail('Internet Connection Failed'));
        }
      });
  };
};

export const fetchMapAllStudentsStart = () => {
  return {
    type: actionTypes.FETCH_MAP_ALL_STUDENTS_START,
  };
};
export const fetchMapAllStudentsSuccess = (data: any) => {
  return {
    type: actionTypes.FETCH_MAP_ALL_STUDENTS_SUCCESS,
    data,
  };
};
export const fetchMapAllStudentsFail = (error: any) => {
  return {
    type: actionTypes.FETCH_MAP_ALL_STUDENTS_FAIL,
    error,
  };
};

export const fetchMapAllStudents = () => {

  return (dispatch: any) => {
    Swal.showLoading();
    dispatch(fetchMapAllStudentsStart());

    axios
      .get(`admin/mapallstudents.php`)
      .then((res) => {
        console.log(res);
        dispatch(fetchMapAllStudentsSuccess(res.data.data));
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data.msg);
        if (err.response) {
          console.log(err.response);
          dispatch(fetchMapAllStudentsFail(err.response.data.msg));
        } else {
          dispatch(fetchMapAllStudentsFail('Internet Connection Failed'));
        }
      }).finally(() => {
        Swal.close();
      });
  };
};
