import React, { Dispatch, memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStateType } from '../../../store/models/root.interface';
import * as actions from '../../../store/actions/index.actions';
import MapForAllStudents from '../../Maps/MapForAllStudents';
import { forEachLeadingCommentRange } from 'typescript';
import { Translate } from '../../../utils/Translate';
const ClusterOverview: React.FC = memo((props: any) => {
  const dispatch: Dispatch<any> = useDispatch();
  const Allstudents: any = useSelector(
    (state: IStateType) => state.AllStudents.data
  );
  const AllstudentsLoading = useSelector(
    (state: IStateType) => state.AllStudents.clusterLoading
  );
  const t = new Translate()
  useEffect(() => {
    dispatch(actions.fetchMapAllStudents());
  }, [dispatch]);

  let assginedTripsToHome: Array<any> = [];
  let assginedTripsToSchool: Array<any> = [];

  if (AllstudentsLoading === false && Allstudents?.assignedtrips.length > 0) {
    Allstudents.assignedtrips.forEach((e: any) => {
      if (e.tohome == 1) {
        let r = assginedTripsToHome.findIndex(i => i.id === e.student_id);
        if (r == -1) {
          assginedTripsToHome.push({
            id: e.student_id, name: e.name,
            lat: +e.location_lat,
            lng: +e.location_lng,
          })
        }
      } if (e.toschool == 1) {
        let r = assginedTripsToSchool.findIndex(i => i.id === e.student_id);
        if (r == -1) {
          assginedTripsToSchool.push({
            id: e.student_id, name: e.name,
            lat: +e.location_lat,
            lng: +e.location_lng,
          })
        }
      }
    });

  }

  let requestedTripsToHome = null;
  if (AllstudentsLoading === false && Allstudents?.requestedtrips.length > 0) {
    requestedTripsToHome = Allstudents.requestedtrips
      .filter((tsph: any) => {
        if (tsph.tohome == 1) {
          return tsph;
        }
      })
      .map((tsph: any) => {
        return {
          id: tsph.student_id,
          r_id: tsph.id,
          name: tsph.name,
          lat: +tsph.location_lat,
          lng: +tsph.location_lng,
        };
      });
    //console.log(requestedTripsToHome);
  }
  let requestedTripsToSchool = null;
  if (AllstudentsLoading === false && Allstudents?.requestedtrips.length > 0) {
    requestedTripsToSchool = Allstudents.requestedtrips
      .filter((tspc: any) => {
        if (tspc.toschool === 1) {
          return tspc;
        }
      })
      .map((tspc: any) => {
        return {
          id: tspc.student_id,
          r_id: tspc.id,
          name: tspc.name,
          lat: +tspc.location_lat,
          lng: +tspc.location_lng,
        };
      });
    //console.log(requestedTripsToSchool);
  }
  const trips = useSelector((state: IStateType) => state.managetrips.trips);
  const tripsloading = useSelector(
    (state: IStateType) => state.managetrips.loading
  );

  useEffect(() => {
    dispatch(actions.fetchTrips(1, 1000));
  }, [dispatch]);

  const [whitchMap, setWhitchMap] = useState<boolean>(true);
  const [SelectedTrip, SetSelectedTrip] = useState<any>("");
  //if(tripsloading == false)
  const changeTrip = (e: any) => {
    console.log(e.target.value)
    console.log(Allstudents.toHomePath,Allstudents.toHomePath[e.target.value])
    SetSelectedTrip(e.target.value);
  }
  return (
    <div className='col-12'>
      <div className='row text-center mb-2'>
        <div className='col-4 justify-content-center text-center'>
          <button
            onClick={() => setWhitchMap(true)}
            className='btn btn-warning fw-bold'
          >
            {t.translate("Trips to Home")}
          </button>
        </div>
        <div className='col-4 justify-content-center text-center'>
          <select className=" form-control" defaultValue={SelectedTrip} onChange={changeTrip}>
            <option value="" >{t.translate("Please select trip to draw route")}</option>
            {AllstudentsLoading === false && tripsloading === false ?
              (Allstudents?.trips?.map((trip: any, index: number) => {
                return (
                  <option key={index} value={trip.id} >{trip.tripname}</option>
                )
              })) : null
            }
          </select>

        </div>
        <div className='col-4 justify-content-center text-center'>
          <button
            onClick={() => setWhitchMap(false)}
            className='btn btn-warning fw-bold'
          >
            {t.translate("Trips to School")}
          </button>
        </div>
      </div>
      {whitchMap === true && AllstudentsLoading === false && tripsloading === false ? (
        <MapForAllStudents
          trips={trips}
          assginedTrips={assginedTripsToHome}
          requestedTrips={requestedTripsToHome}
          path={SelectedTrip === "" ? null : Allstudents.toHomePath[SelectedTrip]}
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyC4R6AN7SmujjPUIGKdyao2Kqitzr1kiRg&v=3.exp&libraries=geometry,drawing,places&key=AIzaSyBaD17OgMbI_0stUcRlvaeIvMuTet_rjdk`}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `500px` }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      ) : null}
      {whitchMap === false && AllstudentsLoading === false && tripsloading === false ? (
        <MapForAllStudents
          trips={trips}
          assginedTrips={assginedTripsToSchool}
          requestedTrips={requestedTripsToSchool}
          path={SelectedTrip === "" ? null : Allstudents.toSchoolPath[SelectedTrip]}
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyC4R6AN7SmujjPUIGKdyao2Kqitzr1kiRg&v=3.exp&libraries=geometry,drawing,places&key=AIzaSyBaD17OgMbI_0stUcRlvaeIvMuTet_rjdk`}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `500px` }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      ) : null}
    </div>
  );
});
export default ClusterOverview;
