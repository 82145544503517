import React, { useEffect, useState } from "react";
import axios from "axios";
import MapBuses from "../../Maps/MapAllBuses";
import { Input } from "reactstrap";
import $ from "jquery";
import Swal from "sweetalert2";
import { Translate } from "../../../utils/Translate";
const BusTracking: React.FC = () => {
  const UrlParam = new URLSearchParams(window.location.search);
  let fromurl = UrlParam.get("from");
  let tourl = UrlParam.get("to");
  let dateurl = UrlParam.get("date");
  let busidurl = UrlParam.get("bus_id");

  const [date, setDate] = useState<string>(dateurl ? dateurl : "");
  const [from, setFrom] = useState<string>(fromurl ? fromurl : "");
  const [to, setTo] = useState<string>(tourl ? tourl : "");

  const [selectedBusId, setSelectedBusId] = useState(busidurl ? busidurl : "");
  const gpsNumber = localStorage.gpsnumber;

  const [buses, setBuses] = useState([]);
  const [busRoutes, setBuseRoutes] = useState([]);
  const [allbusRoutes, setAllBuseRoutes] = useState([]);
  const [updateMap,setUpdateMap] = useState(false);
  const t = new Translate();

  useEffect(() => {
    axios.get("admin/bus/getbuses.php").then((res) => {
      setBuses(res.data.data ?? []);
      $("#datatable").DataTable();
    });
  }, []);

  useEffect(() => {
    if (fromurl) {
      getBusRoute(busidurl, dateurl, fromurl, tourl);
    }
    const intervalId = setInterval(() => {
      axios
        .get("admin/bus/getbuses.php")
        .then((res) => {
          let Buses = res.data.data;
          let sameFlag = true;
          Object.keys(Buses).forEach((key: any) => {
            let b: any = buses[key];
            if (Buses[key].status !== b.status) {
              sameFlag = false;
            }
          });
          if (!sameFlag) {
            setBuses(Buses);
            $("#datatable").DataTable().draw();
          } else {
            return;
          }
        })
        .catch((err) => {});
    }, 25000);

    return () => clearInterval(intervalId); //This is important
  }, [buses, useState]);

  const getBusRoute = (busId: any, date: any, from: any, to: any) => {
    if (busId && from && to) {
      Swal.showLoading();
      axios
        .post("admin/bus/getbusroute.php", {
          bus_id: busId,
          date: date.split("T")[0],
          from: from,
          to: to,
        })
        .then((res) => {
     
          setBuseRoutes(res.data.data?.studentroutes);
          setAllBuseRoutes(res.data.data?.allroutes);
          Swal.close();
        });
    }
  };

  let driverPath: Array<any> = [];
  let alldriverPath: Array<any> = [];
  if (busRoutes?.length > 0) {
    busRoutes?.forEach((e: any) => {
      driverPath?.push({
        time: e.time,
        lat: +e.lat,
        lng: +e.lng,
        student_id: e.student_id,
        fullname: e.fullname,
        open: false,
        id: e.id,
      });
    });
  }
  if (allbusRoutes?.length > 0) {
    allbusRoutes.forEach((e: any) => {
      alldriverPath.push({
        time: e.time,
        lat: +e.lat,
        lng: +e.lng,
      });
    });
  }

  const handleDateChange = (e: any) => {
 
    setDate(e.target.value.split("T")[0]);
  };
  const handleFromChange = (e: any) => {
  
    setFrom(e.target.value);
   
  };
  const handleToChange = (e: any) => {
    setTo(e.target.value);
   
  };
  const handleFilterClick = () => {
    getBusRoute(selectedBusId, date, from, to);
    setUpdateMap(!updateMap)
  };

  const handleBusChange = (e: any) => {
    setSelectedBusId(e.target.value);
  };
  return (
    <div>
      <div className=" row col-12 ">
        <div className="table-responsive bg-light my-5">
          <table
            id="datatable"
            className="table-hover table-bordered text-center"
            style={{ width: "100%" }}
          >
            <thead>
              <tr>
                <th>{t.translate("bus Number")}</th>
                <th>{t.translate("License Plate No.")}</th>
                {gpsNumber == "1" ? (
                  <th>{t.translate("GPS phone number")}</th>
                ) : null}

                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {buses?.length > 0
                ? buses?.map((bus: any) => (
                    <tr key={bus.id}>
                      <td>{bus.busnumber}</td>
                      <td>{bus.license}</td>
                      {gpsNumber == "1" ? <td>{bus.gpsnumber}</td> : null}

                      <td
                        style={{
                          color: bus.status == "Online" ? "green" : "red",
                        }}
                      >
                        <label
                          style={{
                            borderRadius: "50%",
                            background:
                              bus.status == "Online" ? "green" : "red",
                            margin: "10px",
                            border: `1px solid gray`,
                            padding: "8px",
                            boxShadow: `0px 0px 15px ${
                              bus.status == "Online" ? "green" : "red"
                            }`,
                            marginBottom: "-2px",
                          }}
                        >
                          {" "}
                        </label>
                        <span className="m-1">{bus.status}</span>
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        </div>

        <div className="col-6">
          <label>{t.translate("Date")}</label>
          <Input
            type="date"
            className="mb-5"
            name="date"
            defaultValue={date}
            required
            onChange={(e) => {
              handleDateChange(e);
            }}
          />
        </div>

        <div className="col-6">
          <label>{t.translate("Select bus")} </label>

          <select
            className="form-control"
            onChange={(e) => {
              handleBusChange(e);
            }}
          >
            <option value="">
              {t.translate("Please select bus to draw route")}
            </option>
            {buses?.map((bus: any, index: number) => {
              if (bus.id === selectedBusId) {
                return (
                  <option selected key={index} value={bus.id}>{`${t.translate(
                    "bus nubmer"
                  )} : ${bus.busnumber} - ${t.translate(
                    "License plate number"
                  )} : ${bus.license}  `}</option>
                );
              } else {
                return (
                  <option key={index} value={bus.id}>{`${t.translate(
                    "bus nubmer"
                  )} : ${bus.busnumber} - ${t.translate(
                    "License plate number"
                  )} : ${bus.license}  `}</option>
                );
              }
            })}
          </select>
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <div className="col-6">
          <label>{t.translate("from")}</label>
          <Input
            type="time"
            className="mb-5"
            name="from"
            defaultValue={from}
            required
            onChange={(e) => {
              handleFromChange(e);
            }}
          />
        </div>

        <div className="col-6">
          <label>{t.translate("to")}</label>
          <Input
            type="time"
            className="mb-5"
            name="to"
            defaultValue={to}
            required
            onChange={(e) => {
              handleToChange(e);
            }}
          />
        </div>

      </div>
        <div className="text-center mb-5">
          <button className="btn btn-success" onClick={()=>{handleFilterClick()}}>Apply</button>
        </div>
      {allbusRoutes?.length > 0 && (
        <MapBuses
          driver_path={driverPath}
          alldriver_path={alldriverPath}
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyC4R6AN7SmujjPUIGKdyao2Kqitzr1kiRg&v=3.exp&libraries=geometry,drawing,places&key=AIzaSyBaD17OgMbI_0stUcRlvaeIvMuTet_rjdk`}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `500px` }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      )}
    </div>
  );
};

export default BusTracking;
